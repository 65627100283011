import React from 'react'
import {
  Button,
  Dialog as MuiDialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Box,
  styled,
} from '@mui/material'
import useDialog from 'hooks/useDialog'

const StyledBox = styled(Box)`
  text-align: center;
  background-color: #1d2634;
  max-width: 500px;
  padding: 24px;
`

const Dialog: React.FC = () => {
  const { hideDialog, dialogState } = useDialog()

  return (
    <>
      <MuiDialog
        open={dialogState.open}
        onClose={hideDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <StyledBox style={dialogState.style}>
          <DialogTitle id="alert-dialog-title">
            {dialogState.icon ? (
              <Box sx={{ marginTop: 2, textAlign: 'center' }}>
                {dialogState.icon}
              </Box>
            ) : null}
            {dialogState.title}
          </DialogTitle>
          <DialogContent sx={{ textAlign: 'center', padding: '24px 0px' }}>
            <DialogContentText id="alert-dialog-description">
              {dialogState.content}
            </DialogContentText>
          </DialogContent>
          <DialogActions style={dialogState.footerStyle}>
            {dialogState.variant === 'default' ? (
              <>
                <Button
                  onClick={() => {
                    const onCancel = dialogState.onCancel
                    if (onCancel) {
                      onCancel()
                    }
                    hideDialog()
                  }}
                  autoFocus
                  data-testid="dialog-cancel-button"
                >
                  {dialogState.cancelButtonLabel}
                </Button>
                <Button
                  onClick={() => {
                    const onConfirm = dialogState.onConfirm

                    if (onConfirm) {
                      onConfirm()
                    }
                    hideDialog()
                  }}
                  data-testid="dialog-confirm-button"
                >
                  {dialogState.confirmButtonLabel}
                </Button>
              </>
            ) : null}
            {dialogState.variant === 'warning' ? (
              <>
                <Button
                  fullWidth
                  size="large"
                  sx={{ bgcolor: '#90A4AE' }}
                  onClick={() => {
                    const onCancel = dialogState.onCancel
                    if (onCancel) {
                      onCancel()
                    }
                    hideDialog()
                  }}
                  variant="contained"
                  data-testid="dialog-cancel-button"
                >
                  {dialogState.cancelButtonLabel}
                </Button>

                <Button
                  fullWidth
                  size="large"
                  onClick={() => {
                    const onConfirm = dialogState.onConfirm

                    if (onConfirm) {
                      onConfirm()
                    }
                    hideDialog()
                  }}
                  variant="contained"
                  data-testid="dialog-confirm-button"
                >
                  {dialogState.confirmButtonLabel}
                </Button>
              </>
            ) : null}
          </DialogActions>
        </StyledBox>
      </MuiDialog>
    </>
  )
}

export default Dialog
