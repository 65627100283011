import type React from 'react'
import { forwardRef, useCallback, useMemo, useState } from 'react'
import { Box, Modal } from '@mui/material'
import JoditEditor from 'jodit-react'
import styled from '@emotion/styled'

import FileUpload from 'components/FileUpload'

interface ProjectDetail {
  title: string
  description: string
}

interface Props {
  onChange?: (...event: any[]) => void
  onBlur?: () => void
  value?: string
  name?: string
  style?: React.CSSProperties
  placeholder?: string
  error?: any
  defaultValue?: ProjectDetail
}

const StyledEditorWrapper = styled.div`
  .jodit-toolbar-button {
    color: rgba(255, 255, 255, 0.7) !important;
  }
  .jodit-toolbar-button_active,
  .jodit-toolbar-button:hover {
    color: rgba(255, 255, 255, 0.9) !important;
  }
  .jodit-wysiwyg {
    color: #000;
    min-height: 95px;
    font-size: 14px;
    background-color: rgba(255, 255, 255, 0.09) !important;
  }
  .jodit-placeholder {
    color: rgba(255, 255, 255, 0.5) !important;
  }
  .jodit_container .jodit_toolbar {
    min-width: 800px;
    overflow-x: auto;
  }

  ul {
    list-style: inside;
  }

  ol {
    list-style: inside;
    list-style-type: number;
  }
`

const RichTextEditorWithJodit: React.FC<Props> = forwardRef((props, ref) => {
  const {
    value = '',
    onChange = () => {},
    style,
    placeholder = 'Write something...',
    error,
  } = props

  const [isEditorReady, setIsEditorReady] = useState(false)
  const [isImageModalOpen, setIsImageModalOpen] = useState(false)
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false)
  const [editorInstance, setEditorInstance] = useState<any>(null)

  const insertImage = useCallback(
    (imageUrl: string) => {
      if (!editorInstance) {
        console.error('Jodit instance not found! Editor instance is undefined.')
        return
      }

      const htmlToInsert = `
        <p>
          <img 
            src="${imageUrl}" 
            alt="Image"
            style="max-width: 100%;" 
          /><br>
        </p>
      `

      editorInstance.selection.focus()
      editorInstance.selection.insertHTML(htmlToInsert)
    },
    [editorInstance],
  )

  const insertVideo = useCallback(
    (videoUrl: string) => {
      if (!isEditorReady || !editorInstance) {
        console.warn('Editor is not ready for inserting a video!')
        return
      }
      editorInstance.selection.insertHTML(
        `<video src="${videoUrl}" controls style="max-width: 100%;"></video>`,
      )
    },
    [isEditorReady, editorInstance],
  )

  const joditConfig = useMemo(
    () => ({
      toolbarButtonSize: 'medium',
      uploader: {
        insertImageAsBase64URI: false,
      },
      buttons: [
        'undo',
        'redo',
        '|',
        'bold',
        'italic',
        'underline',
        'strike',
        'table',
        '|',
        'ul',
        'ol',
        '|',
        'outdent',
        'indent',
        '|',
        'link',
        '|',
        {
          name: 'customImageUpload',
          iconURL: '/images/quill/upload-image.svg',
          tooltip: 'Upload Image',
          exec: () => {
            setIsImageModalOpen(true)
          },
        },
        /* {
          name: 'customVideoUpload',
          iconURL: '/images/quill/upload-image.svg',
          tooltip: 'Upload Video',
          exec: () => {
            setIsVideoModalOpen(true)
          },
        }, */
        '|',
        'hr',
        'eraser',
        'copyformat',
        '|',
        'align',
        '|',
        'fullsize',
        'source',
      ],
      placeholder,
      events: {
        afterInit: (instance: any) => {
          console.log('Jodit Editor: afterInit fired. Editor is ready.')
          setEditorInstance(instance)
          setIsEditorReady(true)
        },
      },
    }),
    [placeholder],
  )

  const handleEditorChange = useCallback(
    (content: string) => {
      if (content !== value) {
        onChange(content)
      }
    },
    [onChange, value],
  )

  const ImageUploadModal = () => (
    <Modal open={isImageModalOpen} onClose={() => setIsImageModalOpen(false)}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          borderRadius: 1,
        }}
      >
        <FileUpload
          onChange={(url) => {
            if (url && url.includes('https://if-cms')) {
              insertImage(url)
              setIsImageModalOpen(false)
            } else {
              console.warn('No valid file URL selected.')
            }
          }}
        />
      </Box>
    </Modal>
  )

  const VideoUploadModal = () => (
    <Modal open={isVideoModalOpen} onClose={() => setIsVideoModalOpen(false)}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          borderRadius: 1,
        }}
      >
        <FileUpload
          onChange={(url) => {
            if (url) {
              insertVideo(url)
              setIsVideoModalOpen(false)
            } else {
              console.warn('No valid file URL selected.')
            }
          }}
        />
      </Box>
    </Modal>
  )

  return (
    <Box width="100%">
      <StyledEditorWrapper style={style}>
        <JoditEditor
          value={value}
          // @ts-ignore
          config={joditConfig}
          onChange={handleEditorChange}
        />
      </StyledEditorWrapper>

      {error && (
        <Box
          sx={{
            color: '#f44336',
            fontSize: '0.75rem',
            lineHeight: '1.66',
            letterSpacing: '0.03333em',
            mt: '3px',
            ml: '14px',
            fontFamily: 'Roboto","Helvetica","Arial",sans-serif',
          }}
        >
          {error.message}
        </Box>
      )}

      <ImageUploadModal />
      <VideoUploadModal />
    </Box>
  )
})

export default RichTextEditorWithJodit
