import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material'
import loGet from 'lodash/get'
import React, { useEffect } from 'react'
import { Control, Controller, FieldErrorsImpl } from 'react-hook-form'
import useChains from 'state/chain/hooks'
import { useAppDispatch } from 'state/hooks'
import { fetchChains } from 'state/chain/reducer'
import { DefaultValueMap } from '..'

type ChainsDropdownProps = {
  name: string
  label?: string
  isRequired?: boolean
  disabled?: boolean
  control?: Control<DefaultValueMap, any>
  errors: FieldErrorsImpl<{
    [x: string]: any
  }>
  minWidth?: string
}

const ChainsDropdown: React.FC<ChainsDropdownProps> = ({
  name,
  control,
  isRequired,
  label,
  errors,
  disabled,
  minWidth,
}) => {
  const chains = useChains()
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchChains())
  }, [dispatch])

  return (
    <Controller
      control={control}
      name={name}
      rules={isRequired ? { required: 'This field is required.' } : {}}
      render={({ field }) => (
        <>
          <FormControl
            variant="filled"
            sx={{ minWidth: minWidth || 340 }}
            error={!!loGet(errors, name)}
          >
            <InputLabel>{label}</InputLabel>
            <Select
              disabled={disabled}
              value={field.value || ''}
              onChange={(evt) => field.onChange(evt.target.value)}
              data-testid={`select-${name}`}
            >
              {chains.map((chain) => (
                <MenuItem
                  key={chain.chainID}
                  value={chain.chainID}
                  data-testid={`select-option-${name}-${chain.chainName}`}
                >
                  <Box sx={{ display: 'flex' }}>
                    <img
                      width="22px"
                      src={chain.chainImage}
                      alt={chain.chainName}
                    />
                    <Box sx={{ mr: 1 }} />
                    {chain.chainName}
                  </Box>
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              {loGet(errors, name)?.message as string}
            </FormHelperText>
          </FormControl>
        </>
      )}
    />
  )
}

export default ChainsDropdown
