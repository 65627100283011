import React from 'react'
import loGet from 'lodash/get'
import {
  TextField,
  Grid,
  Box,
  Typography,
  useTheme,
  Checkbox,
  Chip,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  FormControlLabel,
} from '@mui/material'
import {
  Control,
  Controller,
  FieldErrorsImpl,
  UseFormRegister,
} from 'react-hook-form'
import FileUpload from 'components/FileUpload'
import ChainsDropdown from './Components/ChainsDropdown'
import TokensDropdown from './Components/TokenDropdown'
import Switch from './Components/Switch'

import StartEndDate, { Options } from './Components/StartEndDate'
import ProjectsDropdown from './Components/ProjectDropdown'

import RichTextEditor from 'components/RichTextEditor'

import Dropdown from './Components/Dropdown'

export type FormField = {
  type?: FieldType
}

export interface GenericFormField extends FormField {
  name: string
  title: string
  defaultValue?: any
  disabled?: boolean
  isRequired?: boolean
  notUpdateable?: boolean
  viewOnly?: boolean
  rows?: number
  placeholder?: string
  error?: boolean
  description?: string
  requiredDimensions?: { width: number; height: number }[]
}

interface TokenFormField extends GenericFormField {
  chainId?: number
}

interface CheckboxFormField extends GenericFormField {
  label: string
}

export interface StartEndDateFormField extends FormField {
  title: string
  startTime: GenericFormField
  endTime?: GenericFormField
  opt?: Options
}

export interface MultiselectFormField extends GenericFormField {
  options: string[]
  maxOptions?: number
}

export interface DropdownFormField extends GenericFormField {
  options: string[]
}
export interface ProjectDropdownFormField extends DropdownFormField {
  useNameAsValue?: boolean
}

export enum FieldType {
  'STRING' = 'string',
  'NUMBER' = 'number',
  'IMAGE' = 'image',
  'TOKEN' = 'token',
  'CHAIN' = 'chain',
  'PROJECT' = 'project',
  'STARTENDDATE' = 'startenddate',
  'MULTISELECT' = 'multiselect',
  'RICHTEXTEDITOR' = 'richtexteditor',
  'DROPDOWN' = 'dropdown',
  'SWITCH' = 'switch',
  'CHECKBOXKYC' = 'checkboxkyc',
}

interface DefaultValueMap {
  [name: string]: any
}

interface Props {
  formFields: (
    | GenericFormField
    | StartEndDateFormField
    | TokenFormField
    | DropdownFormField
    | ProjectDropdownFormField
  )[]
  register: UseFormRegister<DefaultValueMap>
  control?: Control<DefaultValueMap, any>
  errors: FieldErrorsImpl<{
    [x: string]: any
  }>
}

const GeneratedFormFields: React.FC<Props> = ({
  formFields,
  register,
  control,
  errors,
}) => {
  const theme = useTheme()
  const components = {
    [FieldType.CHAIN]: (item: FormField) => {
      const { title, name, isRequired, notUpdateable } =
        item as GenericFormField
      return (
        <Box>
          <ChainsDropdown
            label={title}
            name={name}
            control={control}
            errors={errors}
            isRequired={isRequired}
            disabled={notUpdateable}
          />
        </Box>
      )
    },
    [FieldType.TOKEN]: (item: FormField) => {
      const { title, name, isRequired, chainId, notUpdateable } =
        item as TokenFormField
      return (
        <Box>
          <TokensDropdown
            label={title}
            name={name}
            control={control}
            errors={errors}
            isRequired={isRequired}
            chainId={chainId}
            disabled={notUpdateable}
          />
        </Box>
      )
    },
    [FieldType.PROJECT]: (item: FormField) => {
      const { title, name, isRequired, notUpdateable, useNameAsValue } =
        item as ProjectDropdownFormField
      return (
        <Box>
          <ProjectsDropdown
            label={title}
            name={name}
            control={control}
            errors={errors}
            isRequired={isRequired}
            disabled={notUpdateable}
            useNameAsValue={useNameAsValue}
          />
        </Box>
      )
    },
    [FieldType.IMAGE]: (item: FormField) => {
      const {
        title,
        name,
        placeholder,
        isRequired,
        defaultValue,
        requiredDimensions,
      } = item as GenericFormField

      return (
        <Box>
          <Box>
            <Typography
              variant="subtitle1"
              color={theme.palette.text.primary}
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              {title}
            </Typography>
          </Box>
          <Controller
            control={control}
            rules={isRequired && { required: 'This field is required.' }}
            name={name}
            render={({ field }) => (
              <FileUpload
                {...field}
                defaultValue={defaultValue}
                helperText={placeholder}
                hasError={!!loGet(errors, name)}
                requiredDimensions={requiredDimensions}
              />
            )}
          />
        </Box>
      )
    },

    [FieldType.STARTENDDATE]: (item: FormField) => {
      const { title, startTime, endTime, opt } = item as StartEndDateFormField
      return (
        <StartEndDate
          control={control}
          errors={errors}
          title={title}
          startTime={startTime}
          endTime={endTime}
          opt={opt}
        />
      )
    },
    [FieldType.DROPDOWN]: (item: FormField) => {
      const { title, name, options, isRequired, notUpdateable } =
        item as DropdownFormField
      return (
        <Dropdown
          label={title}
          name={name}
          options={options}
          control={control}
          errors={errors}
          isRequired={isRequired}
          disabled={notUpdateable}
        />
      )
    },
    [FieldType.SWITCH]: (item: FormField) => {
      const { title, name } = item as DropdownFormField
      return (
        <Switch name={name} errors={errors} label={title} control={control} />
      )
    },

    [FieldType.MULTISELECT]: (item: FormField) => {
      const { title, name, options, placeholder, maxOptions, isRequired } =
        item as MultiselectFormField
      return (
        <Box>
          <Controller
            name={name}
            control={control}
            rules={isRequired && { required: 'This field is required.' }}
            render={({ field: { onChange, value = [] } }) => (
              <>
                <InputLabel id={name}>{title}</InputLabel>
                <Box display="flex">
                  <Select
                    style={{
                      flex: 1,
                    }}
                    data-testid={`multiselect-${name}`}
                    error={!!errors[name]}
                    labelId={name}
                    id={name}
                    multiple
                    value={value || []}
                    displayEmpty
                    placeholder={placeholder}
                    onChange={onChange}
                    input={<OutlinedInput />}
                    inputProps={{ 'aria-label': 'Without label' }}
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return (
                          <em style={{ color: '#FFFFFF80' }}>{placeholder}</em>
                        )
                      }

                      return (
                        <Box
                          sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}
                        >
                          {selected.map((value) => (
                            <Chip key={value} label={value} />
                          ))}
                        </Box>
                      )
                    }}
                  >
                    {options.map((name) => (
                      <MenuItem
                        key={name}
                        value={name}
                        disabled={
                          maxOptions > 0 &&
                          value.length >= maxOptions &&
                          value.indexOf(name) === -1
                        }
                      >
                        <Checkbox checked={value.indexOf(name) > -1} />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              </>
            )}
          />
        </Box>
      )
    },
    [FieldType.CHECKBOXKYC]: (item: FormField) => {
      const { name, title, defaultValue = [] } = item as CheckboxFormField
      const handleOnChange = (e) => {
        return e.target.checked ? ['KYC'] : []
      }
      return (
        <Box sx={{ display: 'flex' }}>
          <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            render={({ field: { onChange, value, ref } }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    data-testid={`checkbox-${name}`}
                    defaultChecked={!!defaultValue?.includes('KYC')}
                    checked={value.includes('KYC')}
                    name={name}
                    onChange={(e) => onChange(handleOnChange(e))}
                  />
                }
                label={title}
                sx={{
                  color: '#fff',
                  fontSize: '1rem',
                  lineHeight: '1.75',
                  letterSpacing: '0.00938em',
                }}
              />
            )}
          />
        </Box>
      )
    },
    [FieldType.RICHTEXTEDITOR]: (item: FormField) => {
      const { name, placeholder, defaultValue } = item as GenericFormField

      return (
        <Box>
          <Controller
            control={control}
            name={name}
            //rules={{ required: 'This field is required.' }}
            defaultValue={defaultValue}
            render={({ field, fieldState, formState }) => (
              <RichTextEditor
                onChange={(evt) => field.onChange(evt)}
                value={field.value}
                placeholder={placeholder}
                error={fieldState.error}
              />
            )}
          />
        </Box>
      )
    },
  }
  return (
    <>
      {formFields.map((item) => {
        const {
          viewOnly,
          isRequired,
          notUpdateable,
          defaultValue = '',
          type,
          title,
          name,
          placeholder,
          rows,
        } = item as GenericFormField
        const component = components[type]
        return (
          <Grid
            container
            spacing={2}
            key={name}
            style={{ display: viewOnly ? 'none' : 'block' }}
          >
            <Grid item xs={12}>
              <Box width="100%" marginBottom={2}>
                {component ? (
                  component(item)
                ) : (
                  <Controller
                    control={control}
                    rules={{
                      required: isRequired
                        ? 'This field is required.'
                        : undefined,
                    }}
                    key={name}
                    name={name}
                    defaultValue={defaultValue}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        sx={{ width: '100%' }}
                        variant="filled"
                        data-testid={`input-${name}`}
                        {...register(name, {
                          valueAsNumber: type === FieldType.NUMBER,
                        })}
                        disabled={notUpdateable}
                        type={type}
                        rows={rows}
                        multiline={rows > 0}
                        label={title}
                        placeholder={placeholder || 'Enter here'}
                        helperText={loGet(errors, name)?.message as any}
                        error={!!loGet(errors, name)}
                      />
                    )}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        )
      })}
    </>
  )
}

export default GeneratedFormFields
